.card {
    border: 0;
    border-radius: 0;
    @extend .r-shadow;

    img {
        width: 100%;
    }

    .card-header {
        background-color: #fff;
        padding: $box-padding;
        border-bottom: 0;

        h1 {
            margin-bottom: 0;
            font-size: 18px;
        }

        h2 {
            margin-bottom: 0;
            font-size: 20px;
        }

        h4 {
            margin-bottom: 0;
            font-size: 16px;
        }
    }

    .card-body {
        background-color: #fff;
        padding: $box-padding;
        border-top: 1px dashed $border-color;

        h3 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        p {
            color: $light-grey;
            line-height: 24px;
            font-size: 14px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &.grey {
            background-color: #f7f7f7;
            border-top: 0;
        }

        &.no-border {
            border-top: 0;
        }

        &.text,
        &.skill-list {
            padding: 45px 30px;
        }
    }

    .card-footer {
        background-color: #fff;
        padding: $box-padding;
        border-top: 1px dashed $border-color;

        p {
            margin-bottom: 0;
            text-align: center;
            font-size: 14px;
            color: $light-grey;
        }
    }
}
