ul.skills {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        h4 {
            display: block;
            margin-bottom: 0;
            font-size: 14px;
            color: #000;
            font-weight: 700;
        }

        .progress {
            margin: 15px 0;
            display: block;
            height: 6px;
            position: relative;
            width: 100%;
            overflow: visible;
            background: #fff;
            border: 1px solid $secondary;

            .percentage {
                bottom: 0;
                top: 0;
                position: absolute;
                background-color: $secondary;
            }
        }
    }
}
