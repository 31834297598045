@import "scss/vendors";
@import "scss/fonts";
@import "scss/variables";
@import "scss/navigation";
@import "scss/infoList";
@import "scss/card";
@import "scss/socialLinks";
@import "scss/skills";

@import "scss/resume";

body {
    background-color: #f5f5f5;
    padding: 0 0 40px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Oxygen", sans-serif;
    font-weight: 600;
}

p {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

#heading {
    text-align: center;

    h2 {
        text-transform: uppercase;
    }

    h4 {
        color: $light-grey;
        font-family: "Roboto", sans-serif;
        font-weight: 300;
    }
}

#socials {
    padding: 20px;
}

.r-shadow {
    -webkit-box-shadow: -2px -1px 40px 0px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: -2px -1px 40px 0px rgba(0, 0, 0, 0.17);
    box-shadow: -2px -1px 40px 0px rgba(0, 0, 0, 0.17);
}

#services {
    padding: 45px 30px 15px 30px;

    .row > div {
        margin-bottom: 30px;

        i {
            background-color: $secondary;
            width: 55px;
            height: 55px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;
            text-align: center;
            padding-top: 18px;
            float: left;
            color: #fff;
            font-size: 20px;
            margin-right: 10px;
        }

        span {
            padding-top: 6px;
            display: inline-block;
            line-height: 20px;
            font-size: 16px;
        }
    }
}

#profile {
    margin-bottom: 30px;
    position: relative;
    z-index: 5;
}

@media (min-width: $medium-min-width) {
    #profile {
        margin-bottom: 0;
    }

    body {
        padding: 50px 0 40px 0;
    }
}
