.r-social-links {
    text-align: center;

    a {
        font-size: 16px;
        border: solid 1px #2a2a2a;
        color: #2a2a2a;
        border-radius: 50px;
        width: 34px;
        padding: 4px 0;
        display: inline-block;
        margin-right: 5px;
        text-align: center;

        &:hover {
            color: #fff;
            background-color: #2a2a2a;
        }
    }
}
