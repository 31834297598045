.r-info-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
        flex: 100%;
        font-size: 14px;
        color: $light-grey;

        span {
            color: #212529;
        }
    }
}

@media (min-width: $small-min-width) {
    .r-info-list li {
        flex: 50%;
        max-width: 50%;
    }
}

@media (min-width: $large-min-width) {
    .r-info-list li {
        flex: 33.3333%;
        max-width: 33.3333%;
    }
}
