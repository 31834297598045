$primary: #00b2ed;
$secondary: #243c4f;
$light-grey: #7f7f7f;
$text-color: $secondary;
$box-padding: 25px 30px;
$border-color: #ccc;

$small-min-width: 576px;
$medium-min-width: 768px;
$large-min-width: 992px;
$extra-large-min-width: 1200px;
