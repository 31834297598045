#resume {
    .rounded-icon i {
        width: 55px;
        text-align: center;
        padding: 18px 0;
        color: #fff;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background: $secondary;
        font-size: 18px;
    }

    .resume-items {
        padding-left: 25px;
        margin-bottom: 30px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .resume-item {
            border-left: 2px solid #e5e5e5;
            padding: 0 0 40px 30px;

            &:first-child {
                padding-top: 50px;
            }

            &:last-child:not(:first-child) {
                border-left: 0;

                h2::after {
                    margin-left: -34px;
                }
            }

            h1 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;

                &::after {
                    content: " ";
                    width: 10px;
                    height: 10px;
                    float: left;
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    border-radius: 50px;
                    margin: 0 9px 0 -36px;
                    background-color: $secondary;
                }
            }

            h2 {
                font-size: 16px;
                font-weight: 300;
                margin-bottom: 0;
                color: $secondary;
            }

            .date {
                font-size: 14px;
                padding: 6px 0;
                display: block;
                color: $secondary;
            }

            p {
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}
